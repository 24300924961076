import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../components/DataTable';
import ConfirmationDialog from '../components/ConfirmationDialog';
import { fetchPaymentList, verifyPayment, rejectPayment, refundPayment } from '../api/paymentsApi';
import { format } from 'date-fns';
import styles from './Payment.module.css'; // Import the CSS module
import { convertUTCToLocalTime } from '../utils/Helper';

const Payments = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Customer Name',
        accessor: 'customerName',
      },
      {
        Header: 'Company Name',
        accessor: 'companyName',
      },
      {
        Header: 'Transaction Id',
        accessor: 'transactionId',
        Cell: ({ value }) => (value ? value : 'N/A'),
      },
      {
        Header: 'Date Time',
        accessor: 'paymentDate',
        Cell: ({ value }) => format(new Date(convertUTCToLocalTime(value)), 'yyyy-MM-dd h:mm a'),
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => `Rs.${value.toFixed(2)}`,
      },
      {
        Header: 'Remaining',
        accessor: 'remainingBalance',
        Cell: ({ value }) => `Rs.${value.toFixed(2)}`,
      },
      {
        Header: 'Payment Method',
        accessor: 'paymentMethod',
      },
      {
        Header: 'Image',
        accessor: 'image',
        Cell: ({ value }) => (
          <img
            src={value}
            alt="Image"
            className={styles.paymentImage} // Use the CSS module class
            onClick={() => setSelectedImage(value)}
          />
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <span className={value === 'REJECTED' ? styles.rejectedStatus : value === 'REFUNDED' ? styles.refundedStatus : styles.status}>
            {value}
          </span>
        ),
      },
      {
        Header: 'Action',
        accessor: 'id',
        Cell: ({ row: { original } }) => (
          <div className={styles.actionButtons}>
            {original.status !== 'REJECTED' && original.status !== 'REFUNDED' && (
              <>
                {original.status !== 'VERIFIED' && (
                  <button
                    className={styles.verifyButton}
                    onClick={() => openConfirmationDialog(original.id, 'verify')}
                  >
                    Verify
                  </button>
                )}
                <button
                  className={styles.rejectButton}
                  onClick={() => openConfirmationDialog(original.id, 'reject')}
                >
                  Reject
                </button>
              </>
            )}
            {original.status !== 'REFUNDED' && (
              <button
                className={styles.refundButton}
                onClick={() => openConfirmationDialog(original.id, 'refund')}
              >
                Refund
              </button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const openConfirmationDialog = (id, action) => {
    setCurrentId(id);
    setCurrentAction(action);
    setShowDialog(true);
  };

  const handleConfirmAction = async () => {
    try {
      if (currentAction === 'verify') {
        await verifyPayment(currentId);
      } else if (currentAction === 'reject') {
        await rejectPayment(currentId);
      } else if (currentAction === 'refund') {
        await refundPayment(currentId);
      }
      setRefreshTrigger(prev => !prev);
    } catch (error) {
      console.error(`Error performing ${currentAction} on payment:`, error);
    } finally {
      setShowDialog(false);
      setCurrentId(null);
      setCurrentAction(null);
    }
  };

  const handleCancelAction = () => {
    setShowDialog(false);
    setCurrentId(null);
    setCurrentAction(null);
  };

  const handleDownload = async () => {
    try {
      // Create an anchor element
      const anchor = document.createElement('a');
      anchor.href = selectedImage; // Set the image URL
      anchor.download = 'image.jpg'; // Set the default filename for the download

      // Append the anchor to the body and trigger the click event to start download
      document.body.appendChild(anchor);
      anchor.click();

      // Clean up: remove the anchor from the DOM
      document.body.removeChild(anchor);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <div className={styles.payments}>
      <h1>Payments</h1>
      <DataTable columns={columns} fetchData={fetchPaymentList} refreshTrigger={refreshTrigger} />

      {selectedImage && (
        <div className={styles.modalOverlay} onClick={() => setSelectedImage(null)}>
          <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Full View" className={styles.modalImage} />
            <button className={styles.downloadButton} onClick={handleDownload}>
              Download 
            </button>
          </div>
        </div>
      )}

      <ConfirmationDialog
        show={showDialog}
        onHide={handleCancelAction}
        onConfirm={handleConfirmAction}
        title="Confirm Action"
        body={`Are you sure you want to ${currentAction} this payment?`}
      />
    </div>
  );
};

export default Payments;
