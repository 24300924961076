// src/pages/Product.js
import Layout from "../components/Layout";
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import DataTable from '../components/DataTable';
import { activateProducts, deactivateProducts, fetchProducts ,deleteProducts} from '../api/fetchProduct';
import Button from "../components/Button";
import '../css/Product.css'; 

const Product = () => {
  const navigate = useNavigate();
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');

  const handleStatusClick = async (id, isActive) => {
    try {
      if (isActive) {
        await deactivateProducts(id);
      } else {
        await activateProducts(id);
      }
      setRefreshTrigger(prev => !prev);
    } catch (error) {
      console.error('Error changing Product status:', error);
    }
  };

  const handleDeleteClick = async (id, isActive) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this product?");
      if (confirmDelete) {
        await deleteProducts(id);
        setRefreshTrigger(prev => !prev);
      }
    } catch (error) {
      console.error('Error deleting Product:', error);
    }
  };

  const truncateContent = (content) => {
    return content.length > 30 ? `${content.substring(0, 50)}...` : content;
  };
  const columns = React.useMemo(
    () => [
      {
        Header: 'Product Name',
        accessor: 'title',
        Cell: ({ value }) => truncateContent(value),
      },
      {
        Header: 'Brand',
        accessor: 'brand',
        Cell: ({ value }) => (value ? value : 'N/A'),
      },
      {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value }) => `Rs.${value.toFixed(2)}`,
      },
      {
        Header: 'Cost Price',
        accessor: 'costPrice',
      },
      {
        Header: 'Stock',
        accessor: 'stock',
      },
      {
        Header: 'Unit',
        accessor: 'unit',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ row: { original } }) => (
          <span
            className={original.isActive ? 'active-status' : 'inactive-status'}
            onClick={() => handleStatusClick(original.id, original.isActive)}
            style={{ cursor: 'pointer' }}
          >
            {original.isActive ? 'Active' : 'Inactive'}
          </span>
        ),
      },
      {
        Header: 'Actions',
        Cell: ({ row :{original}}) => (
          <div>
            <Button onClick={() => handleEditClick(original.slug)}>Edit</Button>
          <button className='delete' onClick={() => handleDeleteClick(original.id)}> Delete</button>
          </div>
        ),
      },
    ],
    []
  );

  const handleEditClick = (slug) => {
    navigate(`/edit-products/${slug}`);
  };

  const handleClickToOpen = () => {
    navigate('/add-products');
  };

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
    dispatchEvent(searchKeyword)
  };

  return (
    <div className="product">
      {/* <Search onSearch={handleSearch} /> */}
      <Button title="Add Product" onClick={handleClickToOpen}>
        Add Product
      </Button>
      <DataTable columns={columns} fetchData={fetchProducts} refreshTrigger={refreshTrigger} searchKeyword={searchKeyword} />
    </div>
  );
};

export default Product;
