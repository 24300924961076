import axios from 'axios';
import { API_BASE_URL, endpoints } from '../utils/API.js';


export const addProduct = async (productData) => {
  const formData = new FormData();
  const token = localStorage.getItem('token');
  for (const key in productData) {
    if (key === 'images') {
      productData.images.forEach((file) => {
        formData.append('images', file);
      });
    }else if(key=="mainImage"){
      formData.append("mainImage",productData.mainImage);
    }
     else {
      formData.append(key, productData[key]);
    }
  }

  const response = await axios.post(`${API_BASE_URL}${endpoints.addProduct}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token}`,
    }
  });

  return response.data;
};


export const updateProduct = async (productData) => {
  try { const token = localStorage.getItem('token');
  console.log(productData);
    const response = await axios.post(`${API_BASE_URL}${endpoints.updateProduct}`, productData,
    {headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }});
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const deleteImage = async (id) => {
  try {
      const token = localStorage.getItem('token');
      console.log(token);
      const response = await axios.get(`${API_BASE_URL}${endpoints.deleteImage}/${id}`, {
          headers:{

              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to delete Image');
      }

      return response.data;
  } catch (error) {
      console.error('Error deleting image:', error);
      throw error;
  }
};