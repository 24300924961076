import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import './Header.css';
import '../../css/Sidebar.css'; // Import Sidebar CSS for consistency
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PaymentIcon from '@mui/icons-material/Payment';
import CategoryIcon from '@mui/icons-material/Category';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import InventoryIcon from '@mui/icons-material/Inventory';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogoutClick = () => {
    setDialogOpen(true);
  };

  const handleLogoutConfirm = () => {
    // Add your logout logic here (e.g., clearing user session)
    setDialogOpen(false);
    navigate('/login', { replace: true });
  };

  const handleLogoutCancel = () => {
    setDialogOpen(false);
  };

  // Prevent back navigation after logout
  useEffect(() => {
    if (window.location.pathname === '/login') {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    }
  }, [navigate]);

  return (
    <div>
      <AppBar position="fixed" className="appBar" elevation={0}>
        <Toolbar>
          {isMobile && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Agro Vet Care
          </Typography>
          <Button color="inherit" onClick={handleLogoutClick}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <div role="presentation" className="drawer-content">
          <List>
            <ul className="menu">
              <li className="menu-item">
                <Link to="/" className="menu-link" onClick={handleDrawerToggle}>
                  <FontAwesomeIcon icon={faHome} />&nbsp;
                  Dashboard
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/category-list" className="menu-link" onClick={handleDrawerToggle}>
                  <CategoryIcon icon={CategoryIcon} />&nbsp;
                  Category
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/product-management" className="menu-link" onClick={handleDrawerToggle}>
                  <InventoryIcon icon={InventoryIcon} />&nbsp;
                  Product
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/customer-list" className="menu-link" onClick={handleDrawerToggle}>
                  <FontAwesomeIcon icon={faUsers} />&nbsp;
                  Customer
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/advertisement" className="menu-link" onClick={handleDrawerToggle}>
                  <AddToPhotosIcon icon={AddToPhotosIcon} />&nbsp;
                  Advertisement
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/contact-us" className="menu-link" onClick={handleDrawerToggle}>
                  <AddIcCallIcon icon={AddIcCallIcon} />&nbsp;
                  Contact Us
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/orders" className="menu-link" onClick={handleDrawerToggle}>
                  <ShoppingCartIcon icon={ShoppingCartIcon} />&nbsp;
                  Orders
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/payments" className="menu-link" onClick={handleDrawerToggle}>
                  <PaymentIcon icon={PaymentIcon} />&nbsp;
                  Payments
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/blogs" className="menu-link">
                  <ViewAgendaIcon icon={ViewAgendaIcon} />&nbsp;
                  Blogs
                </Link>
              </li>
            </ul>
          </List>
        </div>
      </Drawer>
      <Dialog
        open={dialogOpen}
        onClose={handleLogoutCancel}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
      
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogoutCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogoutConfirm} color="primary" autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;
