// src/utils/utils.js

export const convertUTCToLocalTime = (utcTime) => {
  // Parse the UTC time string into a Date object
  const date = new Date(utcTime);

  // Create a local Date object to get the correct timezone offset
  const localDate = new Date();

  // Adjust the UTC date for the local timezone offset
  date.setMinutes(date.getMinutes() - localDate.getTimezoneOffset());

  // Format the date object into the local time zone
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
};
