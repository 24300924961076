// src/api/fetchAdvertisementList.js
import { API_BASE_URL, endpoints } from "../utils/API";
import axios from "axios";

export const fetchBlogById = async (id) => {
  const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/blogs/${id}`,{headers: {
      Authorization: `Bearer ${token}`,
    },});
    if (!response.ok) {
      throw new Error('Failed to fetch blog data');
    }
    return response.json();
  };

  export const fetchBlogList = async (pagination) => {
    const { page, pageSize, sort, sortParameter } = pagination;
    const token = localStorage.getItem('token');
  
    try {
      const response = await axios.get(`${API_BASE_URL}${endpoints.blog}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          pageSize,
          sort,
          sortParameter,
        },
      });
  
      // No need to call .json() on axios response
      return response.data;
    } catch (error) {
      console.error('Error fetching advertisement list:', error);
      return { data: [], totalPage: 1 }; // Return default structure on error
    }
  };
  
  export const updateBlog = async ( formData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${endpoints.blog_update}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to update blog');
    }
    return response.json();
  };
  
  export const addBlog = async (formData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${endpoints.create_blog}`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Failed to add blog');
    }
    return response.json();
  };



export const deleteBlog = async (id) => {
  try {
      const token = localStorage.getItem('token');
      console.log(id);
      const response = await axios.get(`${API_BASE_URL}${endpoints.delete_Blog}/${id}`, {
          headers:{

              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to Delete Blog');
      }

      return response.data;
  } catch (error) {
      console.error('Error deactivating Blog:', error);
      throw error;
  }
};