import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
// ... import other pages
import PrivateRoute from './components/PrivateRoute';
// import './App.css';
import Product from './pages/Product';
import ContactUs from './pages/ContactUs';
import CustomerListing from './pages/CustomerListing';
import Advertisement from './pages/Advertisment';
import AddProductPage from './pages/Productadd';
import AddAdvertisementPage from './pages/AddAdvertisementPage.js';
import CustomerDetails from './components/customer/CustomerDetails';
import CategoryListing from './pages/category/CategoryListing';
import AddCategory from './pages/category/CategoryAdd';
import AddCategoryForm from './pages/category/AddCategory';
import Orders from './pages/Orders';
import Payments from './pages/Payment';
import OrderDetail from './pages/orderDetails';

import DashboardPage from './pages/Dashboard';
import Blogs from './pages/blogs';
import BlogAddForm from './pages/blogadd';

function App() {
  return (
    <Router>
      {/* <Layout> */}
      <Routes>
        {/* <ParentComponent/> */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
        <Route path="/category-list" element={<PrivateRoute><CategoryListing /></PrivateRoute>} />
        <Route path="/add-category" element={<PrivateRoute><AddCategory /></PrivateRoute>} />
        <Route path="/add-subcategory" element={<PrivateRoute><AddCategory /></PrivateRoute>} />
        <Route path="/add-categoryForm" element={<PrivateRoute><AddCategoryForm /></PrivateRoute>} />


        {/* <Route path="/products" element={<Product />} /> */}
        <Route path="/product-management" element={<PrivateRoute><Product /></PrivateRoute>} />
        <Route path="/contact-us" element={<PrivateRoute><ContactUs /></PrivateRoute>} />
        <Route path="/customer-list" element={<PrivateRoute><CustomerListing /></PrivateRoute>} />
        <Route path="/customer-details/:customerId" element={<PrivateRoute><CustomerDetails /></PrivateRoute>} />
        <Route path="/advertisement" element={<PrivateRoute><Advertisement /></PrivateRoute>} />
        <Route path="/add-products" element={<PrivateRoute><AddProductPage /></PrivateRoute>} />
        <Route path="/edit-products/:slug" element={<PrivateRoute><AddProductPage /></PrivateRoute>} />
        <Route path="/add-advertisement" element={<PrivateRoute><AddAdvertisementPage /></PrivateRoute>} />
        <Route path="/edit-advertisement" element={<PrivateRoute><AddAdvertisementPage /></PrivateRoute>} />
        <Route path="/orders" element={<PrivateRoute><Orders /></PrivateRoute>} />
        <Route path="/order-details/:slug" element={<PrivateRoute><OrderDetail /></PrivateRoute>} />
        <Route path="/payments" element={<PrivateRoute><Payments /></PrivateRoute>} />
        <Route path="/blogs" element={<PrivateRoute><Blogs /></PrivateRoute>} />
        <Route path="/add-blog" element={<PrivateRoute><BlogAddForm /></PrivateRoute>} />

        {/* Routes for other pages */}
      </Routes>
      {/* </Layout> */}
    </Router>
  );
}

export default App;
