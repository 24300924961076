// src/api/fetchAdvertisementList.js
import { API_BASE_URL, endpoints } from "../utils/API";
import axios from "axios";

export const fetchAdvertisementList = async (pagination) => {
  const { page, pageSize, sort, sortParameter } = pagination;
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(`${API_BASE_URL}${endpoints.advertisementList}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        pageSize,
        sort,
        sortParameter,
      },
    });

    // No need to call .json() on axios response
    return response.data;
  } catch (error) {
    console.error('Error fetching advertisement list:', error);
    return { data: [], totalPage: 1 }; // Return default structure on error
  }
};
