import React, { useEffect, useState } from 'react';
import { getDailyReport } from '../../api/report';
import './Dashboard.css';

const Dashboard = () => {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);

  const fetchData = async (start, end) => {
    try {
      const data = await getDailyReport(start, end);
      setReport(data);
    } catch (error) {
      console.error("Error fetching report", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(startDate,endDate);
  }, [startDate, endDate]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
  };

  const handleButtonClick = () => {
    setLoading(true);
    fetchData(startDate, endDate);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!report) {
    return <div>No report data available</div>;
  }

  return (
    <div className="dashboard">
      <h1>Daily Report</h1>
      <div className="date-range">
        <label>
          Start Date:
          <input
            type="date"
            name="startDate"
            value={startDate}
            onChange={handleDateChange}
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            name="endDate"
            value={endDate}
            onChange={handleDateChange}
          />
        </label>
        <button onClick={handleButtonClick}>Get Report</button>
      </div>

      <div className="report-summary">
        <div className="summary-item">Total Orders: {report.totalOrders}</div>
        <div className="summary-item">Pending Orders: {report.pendingOrders}</div>
        <div className="summary-item">Dispatched Orders: {report.dispatchedOrders}</div>
        <div className="summary-item">Delivered Orders: {report.deliveredOrders}</div>
        <div className="summary-item">Accepted Orders: {report.acceptedOrders}</div>
        <div className="summary-item">Total Revenue: Rs. {report.totalEarnings?.toFixed(2)}</div>
        <div className="summary-item">Total Tax: Rs. {report.totalTax?.toFixed(2)}</div>
        <div className="summary-item">Total Received: Rs. {report.totalReceived?.toFixed(2)}</div>
        <div className="summary-item">Total Due: Rs. {report.totalDue?.toFixed(2)}</div>
      </div>

      <div className="tables-container">
        <div className="table-wrapper">
          <h2>Top Selling Products</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Product Title</th>
                <th>Quantity Sold</th>
                <th>Stock</th>
              </tr>
            </thead>
            <tbody>
              {report.topSellingProducts?.map((product) => (
                <tr key={product.productId}>
                  <td>{product.title}</td>
                  <td>{product.quantitySold}</td>
                  <td>{product.stock}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-wrapper">
          <h2>Latest Orders</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {report.newOrderDTOS?.map((order) => (
                <tr key={order.orderId}>
                  <td>{order.orderId}</td>
                  <td>{new Date(order.orderDate).toLocaleDateString()}</td>
                  <td>Rs. {order.totalAmount?.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="table-wrapper">
          <h2>Latest Payments</h2>
          <table className="table">
            <thead>
              <tr>
                <th>Payment ID</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Method</th>
              </tr>
            </thead>
            <tbody>
              {report.payments?.map((payment) => (
                <tr key={payment.paymentId}>
                  <td>{payment.paymentId}</td>
                  <td>{new Date(payment.paymentDate).toLocaleDateString()}</td>
                  <td>Rs. {payment.amount?.toFixed(2)}</td>
                  <td>{payment.paymentMethod}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
