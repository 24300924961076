import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { addCategory, fetchCategoryList } from '../../api/fetchCategoryList.js';
import '../../pages/category/CategoryForm.css';

import 'react-quill/dist/quill.snow.css';
import Layout from '../../components/Layout.js';
const AddCategoryForm = () => {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategories] = useState([]);
    const [parentCategory, setParentCategory] = useState('');

    useEffect(() => {
        const loadCategories = async () => {
            try {
                const result = await fetchCategoryList();
                if (result && Array.isArray(result["data"])) {
                    setCategories(result["data"]);
                } else {
                    console.error('Unexpected response format:', result);
                    setCategories([]); // Set to empty array if format is unexpected
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        loadCategories();
    }, []);

   


    const handleSubmit = async (event) => {
        event.preventDefault();
        const categoryData = {
          title,
          description,
          parentCategory
        };
    
        try {
         
            await addCategory(categoryData);
            alert('category added successfully!');
          
          navigate('/category-list'); 
        } catch (error) {
          console.error('Error saving category:', error);
          alert('Failed to save category.');
        }
      };
    
   

    return (
 
        <div className="product-form-container">
            <form className="product-form" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label>Title</label>
                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
</div>
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />

                    </div>
                    
               
                <div className="form-actions">
                    <button type="submit">submit</button>
                </div>
            </form>
        </div>

    );
};

export default AddCategoryForm;
