import React, { useState, useEffect } from 'react';
import Layout from "../components/Layout";
import { format } from "date-fns";
import DataTable from "../components/DataTable";
import Button from "../components/Button";
import { fetchAdvertisementList } from "../api/fetchAdvertisementList";
import { activateAdvertisement, deactivateAdvertisement,deleteAdvertisement } from "../api/addAdvertisements.js";
import '../css/Advertisement.css';
import { useNavigate } from 'react-router-dom';
import {convertUTCToLocalTime} from '../utils/Helper';

const Advertisement = () => {
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const navigate = useNavigate();

  const handleClickToOpen = () => {
    navigate('/add-advertisement');
  };

  const handleEditClick = (advertisement) => {
    navigate('/edit-advertisement', { state: { advertisement } });
  };

  const handleStatusClick = async (id, isActive) => {
    try {
      if (isActive) {
        await deactivateAdvertisement(id);
      } else {
        await activateAdvertisement(id);
      }
      setRefreshTrigger(prev => !prev); // Toggle the refresh trigger to force re-fetch
    } catch (error) {
      console.error('Error changing advertisement status:', error);
    }
  };

  // const handleDeleteClick = async (id) => {
  //   try {
     
  //       await deleteAdvertisement(id);
  //     setRefreshTrigger(prev => !prev); // Toggle the refresh trigger to force re-fetch
  //   } catch (error) {
  //     console.error('Error changing advertisement status:', error);
  //   }
  // };
  const handleDeleteClick = async (id, isActive) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this advertisement?");
      if (confirmDelete) {
        await deleteAdvertisement(id);
        setRefreshTrigger(prev => !prev);
      }
    } catch (error) {
      console.error('Error deleting advertisement:', error);
    }
  };
  const columns = React.useMemo(() => [
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Image',
      accessor: 'imageUrl',
      Cell: ({ value }) => <img src={value} alt="Image" style={{ maxWidth: '100px', maxHeight: '100px' }} />,
    },
    {
      Header: 'Target URL',
      accessor: 'targetUrl',
      Cell: ({ value }) => value ? (
        <a href="#" onClick={() => window.open(value, '_blank')} rel="noopener noreferrer">Tap Here</a>
      ) : 'N/A',
    },
    {
      Header: 'Positions',
      accessor: 'positions',
      Cell: ({ value }) => value.join(', '),
    },
    {
      Header: 'Added Time',
      accessor: 'addedAt',
      Cell: ({ value }) => format(new Date(convertUTCToLocalTime(value)), 'yyyy-MM-dd h:mm a'),
    },
    {
      Header: 'Active',
      accessor: 'isActive',
      Cell: ({ row: { original } }) => (
        <span
          className={original.isActive ? 'active-status' : 'inactive-status'}
          onClick={() => handleStatusClick(original.id, original.isActive)}
          style={{ cursor: 'pointer' }}
        >
          {original.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row: { original } }) => (
        <div> <button onClick={() => handleEditClick(original)}>Edit</button>
        <button className='delete' onClick={() => handleDeleteClick(original.id)}> Delete</button>
        </div>
        
      ),
    },
  ], []);

  return (
    <div className='advertisement'>
      <Button title="Advertisement" onClick={handleClickToOpen}>
        Add Advertisement
      </Button>
      <DataTable columns={columns} fetchData={fetchAdvertisementList} refreshTrigger={refreshTrigger} />
    </div>
  );
};

export default Advertisement;
