import { API_BASE_URL, endpoints } from "../utils/API";
import axios from "axios";


export const fetchPaymentList=async(pagination)=>{
    const token = localStorage.getItem('token');
    const { page, pageSize, sort, sortParameter } = pagination;
    const response=await axios.get(`${API_BASE_URL}${endpoints.paymentList}`
    , {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },params: {
            page,
            pageSize,
            sort,
            sortParameter,
          },
      });
     return response.data;
}

export const verifyPayment = async (id) => {
    try {
        const token = localStorage.getItem('token');
        console.log(token);
        const response = await axios.get(`${API_BASE_URL}${endpoints.verify_payment}/${id}`, {
            headers:{

                'Authorization': `Bearer ${token}`,
              },
        });

        if (response.status !== 200) {
            throw new Error('Failed to deactivate advertisement');
        }

        return response.data;
    } catch (error) {
        console.error('Error deactivating advertisement:', error);
        throw error;
    }
};

export const rejectPayment = async (id) => {
    try {
        const token = localStorage.getItem('token');
        console.log(token);
        const response = await axios.get(`${API_BASE_URL}${endpoints.reject_payment}/${id}`, {
            headers:{

                'Authorization': `Bearer ${token}`,
              },
        });

        if (response.status !== 200) {
            throw new Error('Failed to Reject Payment');
        }

        return response.data;
    } catch (error) {
        console.error('Error Rejecting Payment:', error);
        throw error;
    }
};

export const refundPayment = async (id) => {
    try {
        const token = localStorage.getItem('token');
        console.log(token);
        const response = await axios.get(`${API_BASE_URL}${endpoints.refund_payment}/${id}`, {
            headers:{

                'Authorization': `Bearer ${token}`,
              },
        });

        if (response.status !== 200) {
            throw new Error('Failed to Refund Payment');
        }

        return response.data;
    } catch (error) {
        console.error('Error Refunding Payment:', error);
        throw error;
    }
};