import React from 'react';
import Layout from '../components/Layout.js';
import ProductForm from '../components/products/AddForm.js';

const AddProductPage = () => {
  return (
   <div>
    <ProductForm />
  </div>
  );
};

export default AddProductPage;
