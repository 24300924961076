import { API_BASE_URL, endpoints } from '../utils/API.js';
import axios from 'axios';

export const fetchCustomerList=async (pagination)=>{
    const token = localStorage.getItem('token');
    const { page, pageSize, sort, sortParameter } = pagination;
    const response = await axios.get(`${API_BASE_URL}${endpoints.customerList}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page,
        pageSize,
        sort,
        sortParameter,
      },
    });
    
    return response.data;
}

export const getCustomerDetails = async (id) => {
  try {
      const token = localStorage.getItem('token');
      console.log(token);
      const response = await axios.get(`${API_BASE_URL}${endpoints.customerDetails}/${id}`, {
          headers:{
              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to deactivate advertisement');
      }

      return response.data;
  } catch (error) {
      console.error('Error deactivating advertisement:', error);
      throw error;
  }
};





export const activateCustomer = async (id) => {
  try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}${endpoints.customerReactivate}/${id}`, {
          headers:{
              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to activate customer');
      }

      return response.data;
  } catch (error) {
      console.error('Error activating customer:', error);
      throw error;
  }
};

export const verifyCustomer = async (id) => {
  try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}${endpoints.customerVerify}/${id}`, {
          headers:{
              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to verify customer');
      }

      return response.data;
  } catch (error) {
      console.error('Error Verifying customer:', error);
      throw error;
  }
};

export const enableDisableCustomer = async (id) => {
  try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}${endpoints.enableDisableCredit}/${id}`, {
          headers:{
              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to Enable/Disable customer');
      }

      return response.data;
  } catch (error) {
      console.error('Error Enable/Disable customer:', error);
      throw error;
  }
};

export const deactivateCustomer = async (id) => {
  try {
      const token = localStorage.getItem('token');
      console.log(token);
      const response = await axios.get(`${API_BASE_URL}${endpoints.customerDeactivate}/${id}`, {
          headers:{

              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to deactivate customer');
      }

      return response.data;
  } catch (error) {
      console.error('Error deactivating customer:', error);
      throw error;
  }
};