import React, { useState } from 'react';
import '../css/Login.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, endpoints } from '../utils/API.js';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 const navigate=useNavigate();


  const handleLogin = async(event) => {
    event.preventDefault();

    // Basic validation logic
    if (email === '' || password === '') {
      alert('Please enter a valid email and password.');
    } else {
      try {
        const response = await axios.post(`${API_BASE_URL}${endpoints.login}` , {
         'emailId': email,
          'password':password,
        });
  console.log(response.status);
        if (response.status==200) {
          // Store authentication token (replace with your logic)
          localStorage.setItem('token', response.data.authToken); // Example
          navigate('/'); // Redirect to dashboard on successful login
        } else {
          alert('Invalid email or password'); // Replace with more user-friendly error message
        }
      } catch (error) {
        console.error('Login error:', error); // Handle errors from the API call (optional)
        alert('An error occurred. Please try again later.'); // General error message
      }
     
    }
  };

  return (
    <div className="login-container">
      <h1>AgroVet Care</h1>
      <form onSubmit={handleLogin}>
        <div className="input-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email address"
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Enter your password"
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
