import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './blog.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { updateBlog, addBlog } from '../api/fetch_blogs';

const BlogAddForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (location.state && location.state.blog) {
      const blog = location.state.blog;
      setIsEditMode(true);
      setId(blog.id);
      setTitle(blog.title);
      setContent(blog.content);
      setImageUrl(blog.imageUrl); // Assume blog.image is the URL
    } else {
      setIsEditMode(false);
      setId(null);
      setTitle('');
      setContent('');
      setImage(null);
      setImageUrl(null);
    }
  }, [location.state]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageUrl(null); // Clear the imageUrl when a new file is selected
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageUrl(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    if (!title || (!image && !imageUrl) || !content) {
      setError('All fields are required');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (image) {
      formData.append('imageUrl', image);
    }

    try {
      if (isEditMode) {
        formData.append('id', id);
        await updateBlog(formData);
        alert('Blog updated successfully!');
      } else {
        await addBlog(formData);
        alert('Blog added successfully!');
      }
      navigate('/blogs');
    } catch (error) {
      setError('Failed to submit the form');
    }
  };

  return (
    <div className="blog-form-container">
      <form onSubmit={handleSubmit} className="blog-form">
        <div className="form-group">
          <label>Blog Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Blog Image:</label>
          <input
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleImageChange}
            required={!isEditMode && !imageUrl}
          />
          <small>Only one image, not exceeding 2MB.</small>
          {(image || imageUrl) && (
            <div className="image-preview">
              <div className="image-container">
                <img src={image ? URL.createObjectURL(image) : imageUrl} alt="Preview" />
                <button type="button" onClick={handleRemoveImage}>
                  Remove
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="form-group">
          <label>Content:</label>
          <ReactQuill
            value={content}
            onChange={setContent}
            style={{ height: '150px' }}
            placeholder="Write your content here..."
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <div className="form-actions">
          <button type="submit">
            {isEditMode ? 'Update Blog' : 'Add Blog'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlogAddForm;
