import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import Layout from '../../components/Layout';
import DataTable from '../../components/DataTable';
import { activateCategory, deactivateCategory, fetchCategoryList ,deleteCategory} from '../../api/fetchCategoryList';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import '../category/Category.css';
import {convertUTCToLocalTime} from '../../utils/Helper';

const CategoryListing = () => {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const loadCategories = async () => {
    try {
      const fetchedCategories = await fetchCategoryList();
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { title, description, category });
    handleClose();
  };

  const handleStatusClick = async (id, isActive) => {
    try {
      if (isActive) {
        const fetchedCategories = await deactivateCategory(id);
        setCategories(fetchedCategories);
      } else {
        const fetchedCategories = await activateCategory(id);
        setCategories(fetchedCategories);
      }
      setRefreshTrigger(prev => !prev);
    } catch (error) {
      console.error('Error changing category status:', error);
    }
  };

//   const handleDeleteClick = async (id) => {
//     try {
// await deleteCategory(id);

//       setRefreshTrigger(prev => !prev);
//     } catch (error) {
//       console.error('Error changing category status:', error);
//     }
//   };

  const handleDeleteClick = async (id, isActive) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this category?");
      if (confirmDelete) {
        await deleteCategory(id);
        setRefreshTrigger(prev => !prev);
      }
    } catch (error) {
      console.error('Error deleting Category:', error);
    }
  };
  const columns = React.useMemo(() => [
    {
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Added Time',
      accessor: 'addedTime',
      Cell: ({ value }) => convertUTCToLocalTime(value)
    },
    {
      Header: 'Parent Category',
      accessor: 'parentCategoryName',
    },
    {
      Header: 'Active',
      accessor: 'isActive',
      Cell: ({ row: { original } }) => (
        <span
          className={original.isActive ? 'active-status' : 'inactive-status'}
          onClick={() => handleStatusClick(original.id, original.isActive)}
          style={{ cursor: 'pointer' }}
        >
          {original.isActive ? 'Active' : 'Inactive'}
        </span>
      ),
    },
    {
      Header: 'Actions',
      Cell: ({ row :{original}}) => (
        <div>
         
        <button className='delete' onClick={()=>handleDeleteClick(original.id)}> Delete</button>
        </div>
      ),
    },
  ], []);

  const handleClickToOpen = () => {
    navigate('/add-category');
  };

  const handleClickToOpenSubCategory = () => {
    navigate('/add-categoryForm');
  };

  return (
    <div className="category-listing">
      <div className="category-listing-header">
        <h1 className="category-listing-title">Category List</h1>
        <div className="category-listing-buttons">
          <Button onClick={handleClickToOpenSubCategory}>
            Add Category
          </Button>
          <Button onClick={handleClickToOpen}>
            Add Sub Category
          </Button>
        </div>
      </div>
      <DataTable columns={columns} fetchData={fetchCategoryList}refreshTrigger={refreshTrigger} />
    </div>
  );
};

export default CategoryListing;
