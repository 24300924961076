// src/api/fetchProducts.js
import { API_BASE_URL, endpoints } from '../utils/API.js';
import axios from 'axios';

export const fetchProducts = async (pagination) => {
    const token = localStorage.getItem('token');
    const { page, pageSize, sort, sortParameter } = pagination;
    const response = await axios.get(`${API_BASE_URL}${endpoints.products}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page,
        pageSize,
        sort,
        sortParameter,
      },
    });
    return response.data;
  };

  export const fetchProductsBySlug = async (slug) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${endpoints.productDetail}/${slug}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();
    return result;
  };


  export const activateProducts = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}${endpoints.activateProducts}/${id}`, {
            headers:{
                'Authorization': `Bearer ${token}`,
              },
        });

        if (response.status !== 200) {
            throw new Error('Failed to activate Products');
        }

        return response.data;
    } catch (error) {
        console.error('Error activating Products:', error);
        throw error;
    }
};

export const deactivateProducts = async (id) => {
    try {
        const token = localStorage.getItem('token');
        console.log(token);
        const response = await axios.get(`${API_BASE_URL}${endpoints.deactivateProducts}/${id}`, {
            headers:{
                'Authorization': `Bearer ${token}`,
              },
        });

        if (response.status !== 200) {
            throw new Error('Failed to deactivate Products');
        }

        return response.data;
    } catch (error) {
        console.error('Error deactivating Products:', error);
        throw error;
    }
};

export const deleteProducts = async (id) => {
  try {
      const token = localStorage.getItem('token');
      console.log(token);
      const response = await axios.get(`${API_BASE_URL}${endpoints.productsDelete}/${id}`, {
          headers:{
              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to delete Products');
      }

      return response.data;
  } catch (error) {
      console.error('Error delete Products:', error);
      throw error;
  }
};


  