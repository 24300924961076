import axios from 'axios';
import { API_BASE_URL, endpoints } from '../utils/API.js';

export const fetchContactUs= async (pagination)=>{
    const token = localStorage.getItem('token');
    const { page, pageSize, sort, sortParameter } = pagination;
    const response = await axios.get(`${API_BASE_URL}${endpoints.contactUs}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        page,
        pageSize,
        sort,
        sortParameter,
      },
    });
   return response.data;
};

export const respondContact= async (id)=>{
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_BASE_URL}${endpoints.respondContact}/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  const result = await response.json();
  return result;
};