
import React, { useState } from 'react';
import { format } from "date-fns";
import DataTable from "../components/DataTable";
import { activateCustomer, deactivateCustomer, fetchCustomerList } from "../api/fetchCustomerList";
import { useNavigate } from 'react-router-dom';
import Button from "../components/Button";
import './customer.css'; 
import {convertUTCToLocalTime} from '../utils/Helper';

const CustomerListing = () => {
  const navigate = useNavigate();
  const [refreshTrigger,setRefreshTrigger]=useState(false);
  const handleStatusClick = async (id, isActive) => {
    try {
      if (isActive) {
        await deactivateCustomer(id);
      } else {
        await activateCustomer(id);
      }
      setRefreshTrigger(prev=>!prev);
    } catch (error) {
      console.error('Error changing customer status:', error);
    }
  };
  const columns=React.useMemo(
    ()=>[
      {
        Header:'Name',
        accessor:'name',
      },
      {
        Header:'Email',
        accessor:'email',
      },
      {
        Header:'Mobile Number',
        accessor:'mobileNumber',
      },
      {
        Header:'Status',
        accessor:'status',
      },
      {
        Header:'Verified',
        accessor:'isVerified',
        Cell:({value})=>value?"YES":"NO"
      },
      {
        Header:'Added at',
        accessor:'addedAt',
        Cell: ({ value }) => {
          if (!value) {
            return 'Not found';
          }
          const date = new Date(convertUTCToLocalTime(value));
          return format(date, 'yyyy-MM-dd h:mm a');
        },

      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ row: { original } }) => (
          <span
            className={original.isActive ? 'active-status' : 'inactive-status'}
            onClick={() => handleStatusClick(original.id, original.isActive)}
            style={{ cursor: 'pointer' }}
          >
            {original.isActive ? 'Active' : 'Inactive'}
          </span>
        ),
      },
      
      {
        Header:'Action',
        Header: 'Actions',
        Cell: ({ row }) => (

          <Button  onClick={() => handleAction(row.original.id)}>
          View Details</Button>
      
        ),
      },
    ],[]
  );

  const handleAction = (id) => {
    // Navigate to the product detail page
    navigate(`/customer-details/${id}`);
  
  };
    return (
     
        <div className="customer">
          <h1>Customer Listing </h1>
       <DataTable columns={columns} fetchData= {fetchCustomerList} refreshTrigger={refreshTrigger}></DataTable>
        </div>
       
    );
  };
  
  export default CustomerListing;
  