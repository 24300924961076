import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import '../css/DataTable.css';

const DataTable = ({ columns, fetchData, dataKey = 'data', paginationKey = 'totalPage', refreshTrigger }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(20); // Default page size
  const [sort] = useState('descending'); // Default sort order
  const [sortParameter] = useState('id'); // Default sort parameter

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const pagination = { page, pageSize, sort, sortParameter };
        const result = await fetchData(pagination);

        if (result && Array.isArray(result[dataKey])) {
          setData(result[dataKey]);
          setTotalPages(result[paginationKey] || 1); // Set total pages
        } else {
          console.error('Unexpected response format:', result);
          setData([]); // Set to empty array if format is unexpected
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]); // Set to empty array on error
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [page, fetchData, dataKey, pageSize, sort, sortParameter, refreshTrigger]);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="data-table-container">
      <div className="data-table-wrapper">
        <table className="data-table" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} key={column.id}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} key={cell.column.id}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </button>
        <span>Page {page} of {totalPages}</span>
        <button onClick={handleNextPage} disabled={page === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default DataTable;
