import React from 'react';
import AdvertisementForm from '../components/advertisements/AddAdvertisement.js';


const AddAdvertisementPage = () => {
  return (
    <div>
    <AdvertisementForm />
  </div>
  );
};

export default AddAdvertisementPage;
