import Button from '../components/Button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteBlog, fetchBlogList } from '../api/fetch_blogs';
import DataTable from '../components/DataTable';
import './blog.css';
import { convertUTCToLocalTime } from '../utils/Helper';
import { format } from 'date-fns';

const Blogs = () => {
  const navigate = useNavigate();
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const handleClickToOpen = () => {
    navigate('/add-blog');
  };

  const handleEditClick = (blog) => {
    navigate('/add-blog', { state: { blog } });
  };

  const truncateContent = (content) => {
    const div = document.createElement('div');
    div.innerHTML = content;
    const textContent = div.textContent || div.innerText || "";
    return textContent.length > 50 ? `${textContent.substring(0, 50)}...` : textContent;
  };

  const handleDeleteClick = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this blog?");
      if (confirmDelete) {
        await deleteBlog(id);
        setRefreshTrigger(prev => !prev);
      }
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Content',
        accessor: 'content',
        Cell: ({ value }) => truncateContent(value),
      },
      {
        Header: 'Image',
        accessor: 'imageUrl',
        Cell: ({ value }) => (
          <img
            src={value}
            alt="Image"
            className='paymentImage' // Use the CSS module class
          />
        ),
      },
      {
        Header: 'Added Date Time',
        accessor: 'addedDateTime',
        Cell: ({ value }) => format(new Date(convertUTCToLocalTime(value)), 'yyyy-MM-dd h:mm a'),
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <div>
            <button onClick={() => handleEditClick(row.original)}>Edit</button>
            <button className='delete' onClick={() => handleDeleteClick(row.original.id)}> Delete</button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <div className="blog">
      <Button title="Blog" onClick={handleClickToOpen}>
        Add Blog
      </Button>
      <DataTable columns={columns} fetchData={fetchBlogList} refreshTrigger={refreshTrigger} />
    </div>
  );
};

export default Blogs;
