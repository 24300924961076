import React from 'react';
import Layout from '../../components/Layout.js';

import CategoryForm from './CategoryForm.js';

const AddCategory = () => {
  return (
    <Layout><div>
    <CategoryForm />
    {/* <AddCategoryForm/> */}
  </div></Layout>
  );
};

export default AddCategory;
