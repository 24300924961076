import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container, Typography, Box, Paper, Grid, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Alert
} from '@mui/material';
import {convertUTCToLocalTime} from '../utils/Helper';
import styles from './orderDetails.module.css'; // Import CSS module
import { fetchOrdersDetails, cancelOrder, acceptOrder, dispatchOrder, deliverOrder } from '../api/ordersApi'; // Adjust the import path as necessary

const OrderDetail = () => {
  const { slug } = useParams();
  const [orderDetail, setOrderDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openCancel, setOpenCancel] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [openDispatch, setOpenDispatch] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelError, setCancelError] = useState(null);
  const [dispatchTo, setDispatchTo] = useState('');
  const [dispatchMessage, setDispatchMessage] = useState('');
  const [dispatchBy, setDispatchBy] = useState('');
  const [dispatchError, setDispatchError] = useState(null);

  useEffect(() => {
    const getOrderDetail = async () => {
      try {
        const data = await fetchOrdersDetails({ slug });
        setOrderDetail(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    getOrderDetail();
  }, [slug]);

  const handleCancelOrder = async () => {
    if (orderDetail && orderDetail.id) {
      try {
        const updatedOrder = await cancelOrder({ id: orderDetail.id, reason: cancelReason });
        setOrderDetail(updatedOrder);
        setCancelReason('');
        setOpenCancel(false);
      } catch (error) {
        setCancelError(error.message);
      }
    }
  };

  const handleAcceptOrder = async () => {
    if (orderDetail && orderDetail.id) {
      try {
        const updatedOrder = await acceptOrder({ id: orderDetail.id });
        setOrderDetail(updatedOrder);
        setOpenAccept(false);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleDispatchOrder = async () => {
    if (orderDetail && orderDetail.id) {
      try {
        const updatedOrder = await dispatchOrder({
          orderId: orderDetail.id,
          dispatchTo,
          message: dispatchMessage,
          dispatchBy
        });
        setOrderDetail(updatedOrder);
        setDispatchTo('');
        setDispatchMessage('');
        setDispatchBy('');
        setOpenDispatch(false);
      } catch (error) {
        setDispatchError(error.message);
      }
    }
  };

  const handleDeliverOrder = async () => {
    if (orderDetail && orderDetail.id) {
      try {
        const updatedOrder = await deliverOrder({ id: orderDetail.id });
        setOrderDetail(updatedOrder);
        setOpenDispatch(false);
      } catch (error) {
        setError(error.message);
      }
    }
  };

  if (loading) {
    return <Typography variant="h6" align="center">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6" align="center">Error: {error}</Typography>;
  }

  if (!orderDetail) {
    return <Typography variant="h6" align="center">No order details available.</Typography>;
  }

  return (
    <Container className={styles.orderDetailContainer}>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom fontWeight={'bold'}>
          Order Details
        </Typography>
        <Paper className={styles.orderDetailPaper}>
          <Grid container spacing={8}>
            <Grid item xl={12} md={6}>
              <Box className={styles.section}>
                <Typography variant="h6" gutterBottom fontWeight={'bold'}>Customer Details</Typography>
                <Typography variant="body1" fontWeight={'400'}>Name: {orderDetail.customerName}</Typography>
                <Typography variant="body1" fontWeight={'400'}>Delivery Address: {orderDetail.deliveryAddress}</Typography>
                <Typography variant="body1" fontWeight={'400'}>Company Name: {orderDetail.companyName}</Typography>
                
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={styles.section}>
                <Typography variant="h6" gutterBottom fontWeight={'bold'}>Order Details</Typography>
                <Typography variant="body1">Order ID: {orderDetail.uniqueOrderId}</Typography>
                <Typography variant="body1">Order Date: {new Date(convertUTCToLocalTime(orderDetail.orderDateTime)).toLocaleDateString()}</Typography>
                <Typography variant="body1">Order Status: {orderDetail.orderStatus}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider className={styles.divider} />

          <Box className={styles.section}>
            <Typography variant="h6" gutterBottom fontWeight={'bold'}>Delivery Details</Typography>
            {orderDetail.deliveryDetailResponse && (
              <>                <Typography variant="body1">Full Name: {orderDetail.deliveryDetailResponse.fullName}</Typography>
                <Typography variant="body1">Contact Number: {orderDetail.deliveryDetailResponse.contactNumber}</Typography>

                <Typography variant="body1">Province: {orderDetail.deliveryDetailResponse.province}</Typography>
                <Typography variant="body1">City: {orderDetail.deliveryDetailResponse.city}</Typography>
                <Typography variant="body1">Address : {orderDetail.deliveryDetailResponse.address}</Typography>

                <Typography variant="body1">Landmark: {orderDetail.deliveryDetailResponse.landmark}</Typography>
              </>
            )}
          </Box>

          <Divider className={styles.divider} />

          <Box className={styles.section}>
            <Typography variant="h6" gutterBottom fontWeight={'bold'}>Dispatch Details</Typography>
            {orderDetail.dispatchOrderResponse && (
              <>
                <Typography variant="body1">Dispatched By: {orderDetail.dispatchOrderResponse.dispatchedBy}</Typography>
                <Typography variant="body1">Dispatched Date: {new Date(convertUTCToLocalTime(orderDetail.dispatchOrderResponse.dispatchedAt)).toLocaleDateString()}</Typography>
                <Typography variant="body1">Dispatched To: {orderDetail.dispatchOrderResponse.dispatchedTo}</Typography>
                <Typography variant="body1">Message: {orderDetail.dispatchOrderResponse.message}</Typography>
              </>
            )}
          </Box>

          <Divider className={styles.divider} />

          <Box className={styles.section}>
            <Typography variant="h6" gutterBottom fontWeight={'bold'}>Ordered Items</Typography>
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDetail.orderItemResponses && orderDetail.orderItemResponses.map((item) => (
                    <TableRow key={item.productId}>
                      <TableCell>{item.productName}</TableCell>
                      <TableCell>Rs. {item.price}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>Rs. {item.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Divider className={styles.divider} />

          <Box className={styles.section}>
            <Typography variant="h6" gutterBottom fontWeight={'bold'}>Payments</Typography>
            <TableContainer component={Paper} className={styles.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderDetail.paymentResponses && orderDetail.paymentResponses.map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell >{payment.transactionId} </TableCell>
                      <TableCell>Rs. {payment.amount}</TableCell>
                      <TableCell>{new Date(payment.paymentDate).toLocaleDateString()}</TableCell>
                      <TableCell>{payment.paymentMethod}</TableCell>
                      <TableCell>{payment.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Divider className={styles.divider} />

          <Box className={styles.section}>
            <Typography variant="h6" gutterBottom fontWeight={'bold'} alignContent={'flex-end'}>Summary</Typography>
            <Typography variant="body1">Subtotal: Rs. {orderDetail.subTotal}</Typography>
            <Typography variant="body1">Tax: Rs. {orderDetail.tax}</Typography>
            <Typography variant="body1">Discount: Rs. {orderDetail.discountAmount}</Typography>
            <Typography variant="body1">Grand Total: Rs. {orderDetail.grandTotal}</Typography>
            <Typography variant="body1">Total Paid: Rs. {orderDetail.totalPaid}</Typography>
          </Box>

          {orderDetail.orderStatus !== 'CANCELLED' && orderDetail.orderStatus !== 'DELIVERED' && (
            <Box className={styles.actionButtons}>
              {orderDetail.orderStatus === 'PENDING' && (
                <Button variant="contained" color="primary" onClick={() => setOpenAccept(true)}>
                  Accept Order
                </Button>
              )}
              {orderDetail.orderStatus === 'ACCEPTED' && (
                <Button variant="contained" color="primary" onClick={() => setOpenDispatch(true)}>
                  Dispatch Order
                </Button>
              )}
              {orderDetail.orderStatus === 'DISPATCHED' && (
                <Button variant="contained" color="primary" onClick={handleDeliverOrder}>
                  Deliver Order
                </Button>
              )}
              <Button variant="contained" color="secondary" onClick={() => setOpenCancel(true)}>
                Cancel Order
              </Button>
            </Box>
          )}
        </Paper>

        {/* Cancel Order Dialog */}
        <Dialog open={openCancel} onClose={() => setOpenCancel(false)}>
          <DialogTitle>Cancel Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to cancel the order? Please provide a reason.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Cancel Reason"
              fullWidth
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
            />
            {cancelError && <Alert severity="error">{cancelError}</Alert>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCancel(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleCancelOrder} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Accept Order Dialog */}
        <Dialog open={openAccept} onClose={() => setOpenAccept(false)}>
          <DialogTitle>Accept Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to accept the order?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAccept(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleAcceptOrder} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dispatch Order Dialog */}
        <Dialog open={openDispatch} onClose={() => setOpenDispatch(false)}>
          <DialogTitle>Dispatch Order</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide the dispatch details.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Dispatch To"
              fullWidth
              value={dispatchTo}
              onChange={(e) => setDispatchTo(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Message"
              fullWidth
              value={dispatchMessage}
              onChange={(e) => setDispatchMessage(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Dispatched By"
              fullWidth
              value={dispatchBy}
              onChange={(e) => setDispatchBy(e.target.value)}
            />
            {dispatchError && <Alert severity="error">{dispatchError}</Alert>}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDispatch(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDispatchOrder} color="secondary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default OrderDetail;
