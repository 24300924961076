// src/components/AddCategoryButton.js
import React from 'react';
// import '../css/AddCategoryButton.css'; // Create a CSS file for styling
import '../css/Button.css';
const Button = ({children,onClick,title}) => {

  

  return (
    <div className="header">
      <h1>{title}</h1>
          <button className="add-category-button" onClick={onClick}>
            {children}
          </button>
        </div>
  );
};

export default Button;
