// Dashboard.js
import React from 'react';
import Dashboard from '../components/dashboard/Dashboard';

const DashboardPage = () => {
  return (

    <div>

      <Dashboard/>
    </div>


  );
};

export default DashboardPage;
