import { API_BASE_URL, endpoints } from '../utils/API.js';
import axios from 'axios';

export const fetchCategoryList=async (pagination)=>{
    const token = localStorage.getItem('token');
    const { page, pageSize, sort, sortParameter } = pagination;
    const response = await axios.get(`${API_BASE_URL}${endpoints.categoryList}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },params: {
        page,
        pageSize,
        sort,
        sortParameter,
      },
    });
    return response.data;
}

export const fetchCategoryListWithSubCategory=async ()=>{
  const token = localStorage.getItem('token');

  const response = await axios.get(`${API_BASE_URL}${endpoints.categorySubcategoryList}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
}

export const fetchCategoryOnly=async ()=>{
  const token = localStorage.getItem('token');

  const response = await axios.get(`${API_BASE_URL}${endpoints.categoryListOnly}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  });
  return response.data;
}





const fetchCategoriesOnly = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_BASE_URL}${endpoints.categoryListOnly}`,
    {headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },}); // Replace with your actual categories API endpoint
    return response.data;
  } catch (error) {
    console.error('There was an error fetching the categories!', error);
    throw error;
  }
};

export default fetchCategoriesOnly;



export const activateCategory = async (id) => {
  try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}${endpoints.activateCategory}/${id}`, {
          headers:{
              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to activate category');
      }

      return response.data;
  } catch (error) {
      console.error('Error activating category:', error);
      throw error;
  }
};

export const deactivateCategory = async (id) => {
  try {
      const token = localStorage.getItem('token');
      console.log(token);
      const response = await axios.get(`${API_BASE_URL}${endpoints.deactivateCategory}/${id}`, {
          headers:{

              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to deactivate category');
      }

      return response.data;
  } catch (error) {
      console.error('Error deactivating category:', error);
      throw error;
  }
};

export const deleteCategory = async (id) => {
  try {
      const token = localStorage.getItem('token');
      console.log(token);
      const response = await axios.get(`${API_BASE_URL}${endpoints.categoryDelete}/${id}`, {
          headers:{

              'Authorization': `Bearer ${token}`,
            },
      });

      if (response.status !== 200) {
          throw new Error('Failed to Delete Category');
      }

      return response.data;
  } catch (error) {
      console.error('Error delete category:', error);
      throw error;
  }
};



export const addCategory = async (categoryData) => {
  
  const token = localStorage.getItem('token');
  const response = await axios.post(`${API_BASE_URL}${endpoints.categorySave}`, categoryData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }
  });

  return response.data;
};