import axios from 'axios';
import { API_BASE_URL, endpoints } from '../utils/API.js';


export const submitAdvertisement = async (formData,action) => {
    try { const token = localStorage.getItem('token');
    const url = action === 'update' ? `${endpoints.advertisementUpdate}` : endpoints.advertisementAdd;
        const response = await axios.post(`${API_BASE_URL}${url}`, formData,{
            headers:{
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
              },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        console.log('Response Status:', response.status);
        console.log('Response Data:', response.data);

        return response;
    } catch (error) {
        console.error('Error submitting advertisement:', error);
        throw error;
    }
};


export const activateAdvertisement = async (id) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_BASE_URL}${endpoints.activateAdvertisement}/${id}`, {
            headers:{
                'Authorization': `Bearer ${token}`,
              },
        });

        if (response.status !== 200) {
            throw new Error('Failed to activate advertisement');
        }

        return response.data;
    } catch (error) {
        console.error('Error activating advertisement:', error);
        throw error;
    }
};

export const deactivateAdvertisement = async (id) => {
    try {
        const token = localStorage.getItem('token');
        console.log(token);
        const response = await axios.get(`${API_BASE_URL}${endpoints.deactivateAdvertisement}/${id}`, {
            headers:{

                'Authorization': `Bearer ${token}`,
              },
        });

        if (response.status !== 200) {
            throw new Error('Failed to deactivate advertisement');
        }

        return response.data;
    } catch (error) {
        console.error('Error deactivating advertisement:', error);
        throw error;
    }
};

export const deleteAdvertisement = async (id) => {
    try {
        const token = localStorage.getItem('token');
        console.log(id);
        const response = await axios.get(`${API_BASE_URL}${endpoints.deleteAdvertisement}/${id}`, {
            headers:{

                'Authorization': `Bearer ${token}`,
              },
        });

        if (response.status !== 200) {
            throw new Error('Failed to Delete advertisement');
        }

        return response.data;
    } catch (error) {
        console.error('Error deactivating advertisement:', error);
        throw error;
    }
};