import React, { useState, useEffect } from 'react';
import './AddAdvertisements.css';
import { submitAdvertisement } from '../../api/addAdvertisements';
import { useNavigate, useLocation } from 'react-router-dom';

const AdvertisementForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const advertisement = location.state?.advertisement;

    const [formValues, setFormValues] = useState({
        id: '',
        title: '',
        imageUrl: null,
        targetUrl: '',
        positions: []
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (advertisement) {
            setIsEditMode(true);
            setFormValues({
                id: advertisement.id,
                title: advertisement.title,
                imageUrl: advertisement.imageUrl, // Store the URL of the existing image
                targetUrl: advertisement.targetUrl,
                positions: advertisement.positions
            });
            setImagePreview(advertisement.imageUrl); // Preview the existing image
        }
    }, [advertisement]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormValues({
            ...formValues,
            imageUrl: file
        });
        setImagePreview(URL.createObjectURL(file));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('id', formValues.id);
        formData.append('title', formValues.title);

        if (formValues.imageUrl instanceof File) {
            formData.append('imageUrl', formValues.imageUrl);
        }

        formData.append('targetUrl', formValues.targetUrl);
        formValues.positions.forEach(position => formData.append('positions', position));

        try {
            const result = await submitAdvertisement(formData, isEditMode ? 'update' : 'create');
            alert(`Advertisement ${isEditMode ? 'updated' : 'added'} successfully!`);
            console.log('Form submitted successfully:', result);
            navigate('/advertisement');
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Advertisement added Successfully');
            navigate('/advertisement');
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className="advertisement-form">
                <div className="form-group">
                    <label htmlFor="title">Title:</label>
                    <input
                        required
                        type="text"
                        id="title"
                        name="title"
                        value={formValues.title}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="imageUrl">Image:</label>
                    <input
                        type="file"
                        id="imageUrl"
                        accept='.jpg ,.png ,.jpeg'
                        name="imageUrl"
                        onChange={handleFileChange}
                        required={!isEditMode || !formValues.imageUrl} // Conditionally require the image
                    />
                    {imagePreview && (
                        <div className="image-preview">
                            <img src={imagePreview} alt="Image Preview" />
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="targetUrl">Target URL:</label>
                    <input
                        type="text"
                        id="targetUrl"
                        name="targetUrl"
                        value={formValues.targetUrl}
                        onChange={handleChange}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="positions">Positions:</label>
                    <select
                        required
                        id="positions"
                        name="positions"
                        multiple={true}
                        value={formValues.positions}
                        onChange={(e) => {
                            const options = Array.from(e.target.options);
                            const selectedOptions = options.filter(option => option.selected).map(option => option.value);
                            setFormValues({ ...formValues, positions: selectedOptions });
                        }}
                    >
                        <option value="HOME">Homepage</option>
                        <option value="SEARCH">Search</option>
                        <option value="CATEGORY">Category</option>
                    </select>
                </div>
                <div className="form-actions">
                    <button type="submit">{isEditMode ? 'Update' : 'Submit'}</button>
                </div>
            </form>
        </div>
    );
};

export default AdvertisementForm;
