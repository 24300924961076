import React from 'react';
import './ConfirmationDialog.css';

const ConfirmationDialog = ({ show, onHide, onConfirm, title, body }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="confirmation-dialog-overlay" onClick={onHide}>
      <div className="confirmation-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="confirmation-dialog-header">
          <h4>{title}</h4>
          <button className="close-button" onClick={onHide}>&times;</button>
        </div>
        <div className="confirmation-dialog-body">
          {body}
        </div>
        <div className="confirmation-dialog-footer">
          <button className="button" onClick={onHide}>Cancel</button>
          <button className="button button-primary" onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDialog;
