import axios from "axios";
import { API_BASE_URL, endpoints } from "../utils/API";

export const getDailyReport = async (start, end) => {
    try {const token = localStorage.getItem('token');
      const response = await axios.get(`${API_BASE_URL}${endpoints.daily_report}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        params: { start, end },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching daily report", error);
      throw error;
    }
  };