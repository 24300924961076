import Layout from "../components/Layout";
import React from 'react';
import DataTable from "../components/DataTable";
import { fetchContactUs } from "../api/fetchContact";
import { format } from "date-fns";
import { respondContact} from "../api/fetchContact";
import './contactus.css'; 
import {convertUTCToLocalTime} from '../utils/Helper';

const ContactUs = () => {
  const columns=React.useMemo(
    ()=>[
      {
        Header:'Name',
        accessor:'fullName'
      },
      {
        Header:'Email',
        accessor:'email'
      },
      {
        Header:'Mobile Number',
        accessor:'mobileNumber'
      },
      {
        Header:'Message',
        accessor:'message'
      },
      {
        Header:'Added At',
        accessor:'addedAt',
        Cell: ({ value }) => {
          if (!value) {
            return 'Not found';
          }
          const date = new Date(convertUTCToLocalTime(value));
          return format(date, 'yyyy-MM-dd h:mm a');
        },
      },
      {
        Header:'Respond Status',
        accessor:'respondStatus'
      },
      {
        Header:'Respond At',
        accessor:'respondAt',
        Cell: ({ value }) => {
          if (!value) {
            return 'Not found';
          }
          const date = new Date(convertUTCToLocalTime(value));
          return format(date, 'yyyy-MM-dd h:mm a');
        },
      },
      {
        Header:'Action',
        Cell:({row})=>{
          if(row.original.respondStatus === 'PENDING') {
            return <button onClick={()=> handleRespondClick(row.original.id)}>Respond</button>;
          } else {
            return null;
          }
        }
      },
    ],
    []
  );
  
  const handleRespondClick=async(id)=>{
    try {
     await respondContact(id);
     await fetchContactUs();
    } catch (error) {
      console.error('Error responding to message:', error);
    }
  };
  
  return (
    
      <div className="contact" >
        <h1>Contact us </h1>
        <DataTable columns={columns} fetchData={fetchContactUs}></DataTable>
      </div>
     
  );
};

export default ContactUs;