import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCustomerDetails, verifyCustomer ,enableDisableCustomer} from '../../api/fetchCustomerList';
import ConfirmationDialog from '../ConfirmationDialog';

import './CustomerDetails.css';

const CustomerDetails = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [verificationError, setVerificationError] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [enableAction, setEnableAction] = useState(''); // State to handle enable/disable action

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const data = await getCustomerDetails(customerId);
        setCustomer(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerDetails();
  }, [customerId]);

  const handleVerifyCustomer = async () => {
    try {
      const data = await verifyCustomer(customerId);
      if (data != null) {
        setCustomer(data);
        alert('Customer has been successfully verified!');
      }
    } catch (err) {
      setVerificationError('Failed to verify customer. Please try again.');
    }
  };

  const handleImageClick = (image, imageName) => {
    setSelectedImage(image);
    setSelectedImageName(imageName);
  };

  const confirmVerification = () => {
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    setShowConfirmation(false);
    if (enableAction) {
      try {
        const data = await enableDisableCustomer(customerId);
        setCustomer(data);
        alert(`Customer has been ${enableAction === 'enable' ? 'enabled' : 'disabled'} successfully!`);
      } catch (error) {
        console.error('Error enabling/disabling customer:', error);
        alert('Failed to perform the action. Please try again.');
      }
    } else {
      handleVerifyCustomer();
    }
  };

  const handleCancel = () => {
    setShowConfirmation(false);
    setEnableAction(''); // Reset enable/disable action
  };

  const handleEnableDisableClick = (action) => {
    setEnableAction(action);
    setShowConfirmation(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setSelectedImageName('');
  };

  const handleDownload = async () => {
    try {
      const anchor = document.createElement('a');
      anchor.href = selectedImage;
      anchor.download = selectedImageName || 'download';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!customer) {
    return <div>No customer details found</div>;
  }

  const { companyDetails } = customer;

  return (
    <div className="customer-details-container">
      <h1>Customer Details</h1>
      <div className="customer-section">
        <h2>Personal Information</h2>
        <div className="customer-details">
          <p><strong>Full Name:</strong> {customer.fullName}</p>
          <p><strong>Mobile Number:</strong> {customer.mobileNumber}</p>
          <p><strong>Email ID:</strong> {customer.emailId}</p>
          <p><strong>Status:</strong> {customer.status}</p>
          <p><strong>Active:</strong> {customer.isActive ? 'Yes' : 'No'}</p>
          <p><strong>Added Time:</strong> {new Date(customer.addedTime).toLocaleString()}</p>
          <p><strong>Credit Enabled:</strong> {customer.enableCredit ? 'Yes' : 'No'}</p>
        </div>
      </div>
      {companyDetails && (
        <div className="customer-section">
          <h2>Company Details</h2>
          <div className="customer-details">
            <p><strong>Company Name:</strong> {companyDetails.name ?? ""}</p>
            <p><strong>Address:</strong> {companyDetails.address ?? ""}</p>
            <p><strong>Contact Number:</strong> {companyDetails.contactNumber ?? ""}</p>
            <p><strong>Email:</strong> {companyDetails.email ?? ""}</p>
            <p><strong>PAN No:</strong> {companyDetails.panNo ?? ""}</p>
            <p><strong>Contact Person Name:</strong> {companyDetails.contactPersonName ?? ""}</p>
            <p><strong>Contact Person Number:</strong> {companyDetails.contactPersonNumber ?? ""}</p>
          </div>
          <div className="customer-images"> <strong>PAN Certificate:</strong>
            <div className="image-container">
              {companyDetails.panCertificate && (
                <div className="image-wrapper" onClick={() => handleImageClick(companyDetails.panCertificate, 'PAN_Certificate')}>
                  <img
                    src={companyDetails.panCertificate}
                    alt="PAN Certificate"
                    className="thumbnail-image"
                  />
                </div>
              )}
            </div>  <strong>Other Documents:</strong>
            <div className="image-container">
              {companyDetails.otherDocuments && (
                <div className="image-wrapper" onClick={() => handleImageClick(companyDetails.otherDocuments, 'Other_Documents')}>
                  <img
                    src={companyDetails.otherDocuments}
                    alt="Other Documents"
                    className="thumbnail-image"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {selectedImage && (
        <div className="modalOverlay" onClick={closeModal}>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Full View" style={{ width: '100%' }} />
            <button className="downloadButton" onClick={handleDownload}>Download</button>
          </div>
        </div>
      )}
      {customer.status === 'REGISTERED' && !customer.isVerified && (
        <button onClick={confirmVerification}>Verify Customer</button>
      )}
      {customer.enableCredit ? (
        <button onClick={() => handleEnableDisableClick('disable')}>Disable Credit</button>
      ) : (
        <button onClick={() => handleEnableDisableClick('enable')}>Enable Credit</button>
      )}
      {verificationError && <p className="error-message">{verificationError}</p>}
      {showConfirmation && (
        <ConfirmationDialog
          show={showConfirmation}
          onHide={handleCancel}
          onConfirm={handleConfirm}
          title={enableAction === 'enable' ? 'Enable Credit' : 'Disable Credit'}
          body={`Are you sure you want to ${enableAction} credit for this customer?`}
        />
      )}
    </div>
  );
};

export default CustomerDetails;
