// src/api/apiConfig.js
// const API_BASE_URL = 'http://192.168.18.6:8090/v1/api';
const API_BASE_URL = ' https://backend.purwanchalagro.trackyoe.com/Agrovetcare/v1/api';
//          http://192.168.18.6:8090/v1/api           https://backend.purwanchalagro.trackyoe.com/Agrovetcare/v1/api
const endpoints = {
  products: '/admin/products/list',
  productsDelete: '/admin/products/delete',
  productDetail:"/details/products",
  login:'/admin/auth',
  contactUs:'/contact-us/list',
  respondContact:'/contact-us/update-status',
  customerList:'/admin/customer-list',
  customerDeactivate:'/admin/customer/deactivate',
  customerReactivate:'/admin/customer/reactivate',
  customerVerify:'/admin/customer/verify',
  enableDisableCredit:'/admin/customer/enable-disable',
  customerDetails:'/admin/customer',
  categoryList:'/admin/category/list',
  categorySubcategoryList:'/admin/category/sub/list',
  categoryListOnly:'/admin/category/only',
  categorySave:'/admin/category/add',
  categoryDelete:'/admin/category/delete',

  advertisementList:'/advertisement/list',
  advertisementAdd:'/advertisement/save',
  advertisementUpdate:'/advertisement/update',
  activateAdvertisement:'/advertisement/reactivate',
  deactivateAdvertisement:'/advertisement/deactivate',
  deleteAdvertisement:'/advertisement/delete',
  addProduct:'/admin/products/add',
  updateProduct:'/admin/products/update',
  deleteImage:'/delete/image',
  activateProducts:'/admin/products/reactivate',
  deactivateProducts:'/admin/products/deactivate',
  activateCategory:'/admin/category/reactivate',
  deactivateCategory:'/admin/category/deactivate',
  ordersList:'/order',
  search_order:'/order/search',
  ordersDetails:'/admin/order/details',
  cancel_order:'/admin/order/cancel',
  accept_order:'/admin/order/accept',
  dispatch_order:'/admin/order/dispatch',
  deliver_order:'/order/delivery/completed',
  paymentList:'/payment',
  verify_payment:'/payment/verify',
  reject_payment:'/payment/reject',
  refund_payment:'/payment/refund',
  daily_report:'/order/daily-report',

  create_blog:'/blogs/create',
  blog:'/blogs',
  delete:'/blogs/delete',
  reactivate:'/blogs/reactivate',
  blog_details:'/blogs/details',
  blog_update:'/blogs/update',
  delete_Blog:'/blogs/delete'

  // Add other endpoints here
};

export { API_BASE_URL, endpoints };
