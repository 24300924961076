import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { addCategory, fetchCategoryList,fetchCategoryOnly } from '../../api/fetchCategoryList.js';
import '../../pages/category/CategoryForm.css';

import 'react-quill/dist/quill.snow.css';


const CategoryForm = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategories] = useState([]);
  const [parentCategory, setParentCategory] = useState('');

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const result = await fetchCategoryOnly();
        if (result && Array.isArray(result)) {
          setCategories(result);
        } else {
          console.error('Unexpected response format:', result);
          setCategories([]); // Set to empty array if format is unexpected
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    loadCategories();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const categoryData = {
      title,
      description,
      parentCategory
    };

    try {
      await addCategory(categoryData);
      alert('Category added successfully!');
      navigate('/category-list');
    } catch (error) {
      console.error('Error saving category:', error);
      alert('Failed to save category.');
    }
  };

  return (
    <div className="category-form-container">
      <form className="category-form" onSubmit={handleSubmit}>
     
          <div className="form-group">
            <label>Title</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
          </div>
           <div className="form-group">
          <label>Description</label>
          <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </div>
     
          <div className="form-group">
            <label>Category</label>
            <select value={parentCategory} onChange={(e) => setParentCategory(e.target.value)} required>
              <option value="">Select Category</option>
              {category.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
       
        <div className="form-actions">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default CategoryForm;
