// src/pages/Orders.js
import Layout from "../components/Layout";
import { useNavigate } from 'react-router-dom';
import React from 'react';
import DataTable from '../components/DataTable';
import { fetchOrdersList } from '../api/ordersApi';
import Button from "../components/Button";
import { format } from "date-fns";
import './order.css'; 
import {convertUTCToLocalTime} from '../utils/Helper';


const Orders = () => {
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Customer Name',
        accessor: 'customerName',
      },
      {
        Header: 'Company Name',
        accessor: 'companyName',
      },
      {
        Header: 'Order Id',
        accessor: 'uniqueOrderId',
        Cell: ({ value }) => (value ? value : 'N/A'),
      },
      {
        Header: 'Date Time',
        accessor: 'orderDateTime',
        Cell: ({ value }) => format(new Date(convertUTCToLocalTime(value)), 'yyyy-MM-dd h:mm a'),
      },
      {
        Header: 'Subtotal',
        accessor: 'subTotal',
        Cell: ({ value }) => `Rs.${value.toFixed(2)}`,
      },
      {
        Header: 'Tax',
        accessor: 'tax',
        Cell: ({ value }) => `Rs.${value.toFixed(2)}`,
      },
      // {
      //   Header: 'Discount',
      //   accessor: 'discountAmount',
      //   Cell: ({ value }) => `Rs.${value.toFixed(2)}`,
      // },
      {
        Header: 'Grand Total',
        accessor: 'grandTotal',
        Cell: ({ value }) => `Rs.${value.toFixed(2)}`,
      },
      {
        Header: 'Total Paid',
        accessor: 'totalPaid',
        Cell: ({ value }) => `Rs.${value.toFixed(2)}`,
      },
      {
        Header: 'Order Status',
        accessor: 'orderStatus',
      },
      {
        Header: 'Actions',
        Cell: ({ row }) => (
          <div>
            <Button onClick={() => handleDetailsClick(row.original.id)}>View Details</Button>
          </div>
        ),  
      },
    ],
    []
  );

  const handleDetailsClick = (id) => {
    navigate(`/order-details/${id}`);
  };

  return (
    <div className="orders">
  <h1>Orders</h1>
        <DataTable columns={columns} fetchData={fetchOrdersList} />
    </div>
  );
};

export default Orders;
