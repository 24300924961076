import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchCategoryListWithSubCategory } from '../../api/fetchCategoryList.js';
import { addProduct, updateProduct, deleteImage } from '../../api/addProduct.js';
import './AddForm.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { fetchProductsBySlug } from '../../api/fetchProduct.js';

const ProductForm = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [brand, setBrand] = useState('');
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const [categories, setCategories] = useState([]);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [hasTax, setHasTax] = useState(false);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [unit, setUnit] = useState('');
  const [stock, setStock] = useState(0);
  const [price, setPrice] = useState(0);
  const [costPrice, setCostPrice] = useState(0);
  const [id, setId] = useState(null);
  const [showMessage, setShowMessage] = useState(true);

  const handleDescriptionChange = (value) => {
    setDescription(value);
    if (value.trim().length > 0) {
      setShowMessage(false);
    } else {
      setShowMessage(true);
    }
  };

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const result = await fetchCategoryListWithSubCategory();
        if (result && Array.isArray(result)) {
          setCategories(result);
        } else {
          console.error('Unexpected response format:', result);
          setCategories([]);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    loadCategories();
  }, []);

  useEffect(() => {
    if (slug) {
      setIsEditMode(true);
      const loadProduct = async () => {
        try {
          const product = await fetchProductsBySlug(slug);
          setTitle(product.title);
          setDescription(product.description);
          setBrand(product.brand);
          setCategoryId(product.categoryId);
          setHasDiscount(product.hasDiscount);
          setDiscountPercentage(product.discountPercentage);
          setUnit(product.unit);
          setStock(product.stock);
          setPrice(product.price);
          setCostPrice(product.costPrice);
          setImages(product.images || []);
          setMainImage(product.imageUrl || null);
          setId(product.id);
          setHasTax(product.hasTax);
          setTaxPercentage(product.taxPercentage);
        } catch (error) {
          console.error('Error fetching product:', error);
        }
      };

      loadProduct();
    } else {
      setIsEditMode(false);
      setTitle('');
      setDescription('');
      setBrand('');
      setImages([]);
      setCategoryId('');
      setHasDiscount(false);
      setDiscountPercentage(0);
      setHasTax(false);
      setTaxPercentage(0);
      setUnit('');
      setStock(0);
      setPrice(0);
      setCostPrice(0);
    }
  }, [slug]);

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter((file) => ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type) && file.size <= 1048576);
    if (images.length + validFiles.length > 5) {
      alert('You can only upload a maximum of 5 images.');
    } else {
      setImages([...images, ...validFiles]);
    }
    if (selectedFiles.length !== validFiles.length) {
      alert('Only jpg, png, and jpeg formats are allowed, and the file size must be 1 MB or less.');
    }
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file && ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type) && file.size <= 1048576) {
      setMainImage(file);
    } else {
      alert('Only jpg, png, and jpeg formats are allowed, and the file size must be 1 MB or less.');
    }
  };

  const handleRemoveImage = async (index) => {
    const imageToRemove = images[index].id;
    const updatedImages = images.filter((_, i) => i !== index);

    if (imageToRemove != null) {
      try {
        await deleteImage(imageToRemove);
      } catch (error) {
        console.error('Error deleting image:', error);
        alert('Failed to delete image.');
        return;
      }
    }

    setImages(updatedImages);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const productData = {
      title,
      description,
      brand,
      images,
      mainImage,
      categoryId,
      hasDiscount,
      discountPercentage,
      hasTax,
      taxPercentage,
      unit,
      stock,
      price,
      costPrice,
      id,
    };

    try {
      if (isEditMode) {
        await updateProduct(productData);
        alert('Product updated successfully!');
        navigate('/product-management');
      } else {
        await addProduct(productData);
        alert('Product added successfully!');
        navigate('/product-management');
      }
    } catch (error) {
      console.error('Error saving product:', error);
      alert('Failed to save product.');
    }
  };

  return (
    <div className="product-form-container">
      <form onSubmit={handleSubmit} className="product-form">
        <div className="form-row">
          <div className="form-group">
            <label>Product Name:</label>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
            <small>*Product Name should not exceed 30 characters</small>
          </div>
          <div className="form-group">
            <label>Brand:</label>
            <input type="text" value={brand} onChange={(e) => setBrand(e.target.value)} />
          </div>
          <div className="form-group">
            <label>Cost Price:</label>
            <input type="number" required value={costPrice} onChange={(e) => setCostPrice(e.target.value)} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Price:</label>
            <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Has Discount:</label>
            <input type="checkbox" checked={hasDiscount} onChange={(e) => setHasDiscount(e.target.checked)} />
          </div>
          <div className="form-group">
            <label>Discount Percentage:</label>
            <input type="number" value={discountPercentage} onChange={(e) => setDiscountPercentage(e.target.value)} disabled={!hasDiscount} />
          </div>
        </div>
        <div className="form-group">
          <label>Has Tax:</label>
          <input type="checkbox" checked={hasTax} onChange={(e) => setHasTax(e.target.checked)} />
        </div>
        <div className="form-group">
          <label>Tax Percentage:</label>
          <input type="number" value={taxPercentage} onChange={(e) => setTaxPercentage(e.target.value)} disabled={!hasTax} />
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Category:</label>
            <select value={categoryId} onChange={(e) => setCategoryId(e.target.value)} required>
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Unit:</label>
            <input type="text" value={unit} onChange={(e) => setUnit(e.target.value)} required />
          </div>
          <div className="form-group">
            <label>Stock:</label>
            <input type="number" required value={stock} onChange={(e) => setStock(e.target.value)} />
          </div>
        </div>
        <div className="form-description">
          <div className="form-group">
            <label>Product Description:</label>
            <ReactQuill
              value={description}
              onChange={handleDescriptionChange}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link'],
                  ['clean'],
                ],
              }}
            />
            {showMessage && (
              <div className="form-message">
                <small>Content is required*</small>
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>Main Product Image:</label>
          {mainImage && (
            <div className="image-preview">
              <img src={mainImage} alt="Main Preview" />
              <button type="button" onClick={() => setMainImage(null)}>Remove</button>
            </div>
          )}
          <input type="file" onChange={handleMainImageChange} accept=".jpg,.jpeg,.png" />
        </div>
        <div className="form-group">
          <label>Product Images:</label>
          <input type="file" multiple onChange={handleImageChange} accept=".jpg,.jpeg,.png" />
          <div className="image-preview">
            {images.map((image, index) => (
              <div key={index} className="image-container">
                <img src={image.image} alt={`Preview ${index}`} />
                <button type="button" onClick={() => handleRemoveImage(index)}>Remove</button>
              </div>
            ))}
          </div>
        </div>
        <div className="form-actions">
          <button type="submit">{isEditMode ? 'Update Product' : 'Add Product'}</button>
          <button type="button" onClick={() => navigate('/product-management')}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default ProductForm;
