import { API_BASE_URL, endpoints } from "../utils/API";
import axios from "axios";

export const fetchOrdersList=async(pagination)=>{
    const token = localStorage.getItem('token');
    const { page, pageSize, sort, sortParameter } = pagination;
    const response=await axios.get(`${API_BASE_URL}${endpoints.ordersList}`
    , {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },params: {
          page,
          pageSize,
          sort,
          sortParameter,
        },
      });
      return response.data;
}

export const searchOrders=async({keyword})=>{
  const token = localStorage.getItem('token');

  const response=await axios.get(`${API_BASE_URL}${endpoints.search_order}/${keyword}`
  , {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
    return response.data;
}

export const fetchOrdersDetails=async({slug})=>{
  const token = localStorage.getItem('token');
  const response=await fetch(`${API_BASE_URL}${endpoints.ordersDetails}/${slug}`
  , {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();
    return result;
}

export const cancelOrder=async({id,reason})=>{
  const token = localStorage.getItem('token');
  const response=await fetch(`${API_BASE_URL}${endpoints.cancel_order}`
  , {
    method: 'POST',
    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({id:id, reason: reason }),
  });
    const result = await response.json();
    return result;
}
export const acceptOrder=async({id})=>{
  const token = localStorage.getItem('token');
  const response=await fetch(`${API_BASE_URL}${endpoints.accept_order}/${id}`
  , {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },

  });
    const result = await response.json();
    return result;
}

export const dispatchOrder = async ({ orderId, dispatchTo, message, dispatchBy }) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_BASE_URL}${endpoints.dispatch_order}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ orderId, dispatchTo, message, dispatchBy })
  });
  const result = await response.json();
  return result;
};


export const deliverOrder=async({id})=>{
  const token = localStorage.getItem('token');
  const response=await fetch(`${API_BASE_URL}${endpoints.deliver_order}/${id}`
  , {
    method: 'GET',
    headers: {'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },

  });
    const result = await response.json();
    return result;
}